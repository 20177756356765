import {
  Component,
  createMemo,
  createSignal,
  ErrorBoundary,
  JSXElement,
  onMount,
  Show,
  Suspense,
} from "solid-js";
import {
  A,
  createAsync,
  RouteDefinition,
  RouteSectionProps,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "@solidjs/router";
import { clientOnly } from "@solidjs/start";
import { EventType } from "@solid-primitives/analytics";
import { Title, Meta } from "@solidjs/meta";
import { Icon } from "solid-heroicons";
import { calculator, chatBubbleLeft, heart } from "solid-heroicons/outline";
import { APIError, checkError } from "~/services/roma-api/errors";
import { getFavourites } from "~/services/favourites";
import { launchLiveChat } from "~/utils/livechat";
import { PT } from "~/utils/products";
import {
  ProductPriceResponse,
  ProductSuggestion,
} from "~/services/roma-api/products/types";
import {
  useErrorContext,
  useSessionContext,
  useSiteContext,
} from "~/utils/contexts";
import { useProduct } from "~/services/products";

import { BaseProductSlider, Collapsible } from "~/components/ui";
import { BaseSkeleton, Modal } from "~/components/utility";
import { GenericError } from "~/components/utility/errors";
import { Img } from "~/components/image";
import {
  AssetDownloadButton,
  FrameCalculator,
  MouldingNotFound,
  PDPImageGallery,
} from "~/components/product/product-page";
import Body from "~/components/Body";
import Breadcrumb from "~/components/Breadcrumb";
import Button from "~/components/Button";

import otp from "~/assets/otp.png";
import tnc from "~/assets/tnc.png";

const ClientRecentlyViewed = clientOnly(() =>
  import("~/components/shop").then((mod) => ({ default: mod.RecentlyViewed }))
);
const ClientViewerCount = clientOnly(() =>
  import("~/components/product/product-page").then((mod) => ({
    default: mod.ViewerCount,
  }))
);

export const route = {
  preload: ({ params }) =>
    useProduct(params.sku, { available_list: true, suggestions: true }),
} satisfies RouteDefinition;

export type FormattedPricing = ProductPriceResponse & {
  availableAs?: string[];
  defaultPlantLowStock?: boolean;
  anyPlantLowStock?: boolean;
};

export const ProductPageButtons: Component<{
  product: {
    SKU: string;
    OnSale?: boolean;
    OnSaleNote?: string;
    Collection?: string;
    ColourDescription?: string;
  };
  onOrderClick: VoidFunction;
  class?: string;
  buttonText?: string | JSXElement;
  disabled?: boolean;
  hideBuyButton?: boolean;
}> = (props) => {
  const [, setParams] = useSearchParams();
  const { toggleFav, isPartner, permission, isFavourited } =
    useSessionContext();
  const { track, trackFBQ, recentlyViewed } = useSiteContext();
  const { addError } = useErrorContext();
  const location = useLocation();
  const type = createMemo(() => {
    if (location.pathname.includes("photo-frame")) {
      return PT.PHOTOFRAME;
    } else if (location.pathname.includes("gallery-frame")) {
      return PT.GALLERYFRAME;
    } else if (location.pathname.includes("mirror")) {
      return PT.MIRROR;
    } else {
      return PT.MOULDING;
    }
  });

  const favourites = createAsync(() => getFavourites());
  const isFav = () => isFavourited(favourites(), props.product.SKU, type());
  const handleToggleFav = async () => {
    const fav = favourites()?.get(`${props.product.SKU}-${type()}`)?.ID;
    // toggleFav(props.product.SKU, type(), fav);

    try {
      await toggleFav(props.product.SKU, type(), fav);
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[handleToggleFav] caught error: ", err);
      }
      addError(err, {
        severity: "warning",
        autoDisappear: true,
        title: "Favourites Error",
        showDetails: true,
        message: `Error ${fav ? "removing" : "adding"} item ${
          props.product.SKU
        } ${
          fav ? "from" : "to"
        } favourites. Please try again later. If this error persists, kindly contact customer support.`,
      });
    }
  };

  onMount(() => {
    if (props.product.SKU && type()) {
      recentlyViewed.add({ mouldingNumber: props.product.SKU, type: type() });
    }
  });

  return (
    <Show when={props.product}>
      <div
        class="flex flex-col gap-2 pt-6 pb-0"
        classList={{ [`${props.class}`]: !!props.class }}
      >
        <Show
          when={
            ((isPartner() && permission.PLACEORDER) || !isPartner()) &&
            !props.hideBuyButton
          }
        >
          <Button
            class="w-full"
            disabled={props.disabled}
            onClick={() => {
              isPartner() ? props.onOrderClick() : setParams({ signIn: true });
            }}
          >
            <p class="font-bold text-sm">
              {isPartner()
                ? props.buttonText ?? "Order Now"
                : "Sign in to order"}
            </p>
          </Button>

        </Show>
        {/* TODO Error-Boundary ?? Necessary here? */}
        <ErrorBoundary fallback={<></>}>
          <Show when={isPartner()}>
            <Button
              class="w-full"
              style="outline"
              onClick={() => {
                if (!isFav()) {
                  track(EventType.Event, {
                    category: "favourite",
                    action: "selected",
                    label: props.product.SKU,
                    value: type(),
                  });
                  trackFBQ("AddToWishlist", {
                    content_name: `${props.product.SKU} - ${
                      props.product.Collection
                    }, ${props.product.ColourDescription}, ${type()}`,
                  });
                }
                handleToggleFav();
              }}
            >
              <div class="flex font-bold text-sm justify-center space-x-2 items-center">
                <Show fallback={<p>Add to Favorites</p>} when={isFav()}>
                  <p>Remove from Favourites</p>
                </Show>
                <Icon path={heart} class="w-4" stroke-width={2} />
              </div>
            </Button>
          </Show>
        </ErrorBoundary>
        <ClientViewerCount
            class="!text-roma-blue text-base flex justify-center pt-2"
            sku={props.product.SKU}
          />
        <Show
          when={
            isPartner() && props.product?.OnSale && props.product?.OnSaleNote
          }
        >
          <div
            class="bg-red-50 rounded-lg shadow-sm p-4 mt-4 text-base [&>a]:text-roma-blue"
            innerHTML={props.product.OnSaleNote}
          />
        </Show>
      </div>
    </Show>
  );
};

export default function ProductLanding(props: RouteSectionProps) {
  const { isPartner, session } = useSessionContext();
  const { addError } = useErrorContext();
  const [calcModal, setCalcModal] = createSignal(false);
  const [productPageError, setProductPageError] = createSignal<
    "default" | "not_found"
  >();
  const params = useParams();
  const navigate = useNavigate();

  const type = createMemo(() => {
    if (props.location.pathname.includes("photo-frame")) {
      return PT.PHOTOFRAME;
    } else if (props.location.pathname.includes("gallery-frame")) {
      return PT.GALLERYFRAME;
    } else if (props.location.pathname.includes("mirror")) {
      return PT.MIRROR;
    } else {
      return PT.MOULDING;
    }
  });

  const product = createAsync(async () => {
    setProductPageError(undefined);
    try {
      const data = await useProduct(props.params.sku, {
        available_list: true,
        suggestions: true,
        current_viewers: true,
        type: type(),
      });

      // See if API can be changed to return a 404 instead of 200 empty obj?
      if (Object.keys(data).length === 0) {
        throw new APIError({
          code: "NOT_FOUND",
          message: "Invalid or non-existent moulding.",
          statusCode: 404,
          path: `/products/${params.sku}`,
        });
      }

      return data;
    } catch (error) {
      const err = checkError(error);

      const defaultErrorConfig = {
        severity: "critical" as const,
        showDetails: true,
        title: "An error occurred",
        message:
          "An error occurred while retrieving product information. Please try again. If this error persists, kindly contact customer support. We apologize for the inconvenience.",
        actions: [
          {
            label: "Refresh",
            action: () => window.location.reload(),
          },
          {
            label: "Contact Support",
            action: async () => navigate("/support"),
          },
        ],
      };

      if (import.meta.env.DEV) {
        console.log("[[sku].tsx]: Error caught in createAsync: :", err);
      }

      if (err instanceof APIError) {
        switch (err.code) {
          case "NOT_FOUND":
            setProductPageError("not_found");
            return;
          default:
            // useless right now..currently will always show MouldingNotFound component.
            setProductPageError("default");
            addError(err, defaultErrorConfig);
            return;
        }
      }
      setProductPageError("default");
      addError(err, defaultErrorConfig);
      return;
    }
  });


  const suggestionList = createMemo(() => {
    if (product() && product()?.Suggestions) {
      const arr = product()?.Suggestions.reduce((memo, item) => {
        memo.push({
          type: type(),
          data: item,
        });
        return memo;
      }, [] as { type: PT; data: ProductSuggestion }[]);
      return arr;
    }
  });

  return (
    <ErrorBoundary
      fallback={(error, reset) => {
        return <GenericError error={error} reset={reset} />;
      }}
    >
      <Suspense fallback={<BaseSkeleton height={400} />}>
        <Show when={productPageError() && !product()}>
          <MouldingNotFound
            sku={props.params.sku}
            type={type() ?? PT.MOULDING}
          />
          {/* TODO - Switch/Match with other error codes? */}
        </Show>
        <Show when={product()}>
          <Title>
            {product()?.Collection ? `${product()?.Collection}, ` : ""}
            {product()?.ColourDescription ?? ""} ({`${props.params.sku}`})
            | RomaMoulding.com
          </Title>
          <Meta name="Algolia crawler" content="noindex" />
          <Meta name="description" content={product()?.SEODescription} />
          <Meta name="keywords" content={product()?.SEOKeywords} />
          {/* <Meta
          name="og:image"
          content={`${import.meta.env.VITE_ROMA_CDN}/mouldings/${
            product().SKU
          }-1.png`}
        /> */}
          <Body>
            <Modal
              open={calcModal}
              onClose={() => {
                setCalcModal(false);
              }}
            >
              <FrameCalculator
                data={{
                  sku: product()!.SKU,
                  collection: product()!.Collection,
                  colour: product()!.ColourDescription,
                  thumb: `${import.meta.env.VITE_ROMA_CDN}/mouldings/${
                    product()!.SKU
                  }-1.png?v=2`,
                  width: product()!.Width,
                }}
              />
            </Modal>
            <div class="flex flex-col md:flex-row md:items-center boundary-outer">
              <Breadcrumb
                class="mt-4 grow"
                breadcrumbs={[
                  // { pageTitle: "Home", url: "/" },
                  { pageTitle: "Shop", url: "/shop" },
                  {
                    pageTitle: product()?.Category,
                    url: `/shop?category=${product()?.Category}`,
                  },
                  {
                    pageTitle: `${product()?.SKU} ${product()?.Collection} - ${
                      product()?.ColourDescription
                    }`,
                    url: "/",
                  },
                ]}
              />
            </div>
            <main class="grid md:grid-cols-product-page items-start sm:mt-7 boundary-outer relative gap-2 sm:gap-10">
              <div class="md:col-span-4 relative">
                <PDPImageGallery type={type} product={product} />
              </div>
              <div class="flex flex-col md:col-span-2 divide-y child:py-4 sticky top-4 -mt-4">
                <Suspense>{props.children}</Suspense>
                <Collapsible
                  name="sustainability"
                  trigger="Sustainability"
                  triggerClass="font-bold"
                >
                  <div class="my-4 text-roma-dark-grey text-sm child:pb-3 leading-7">
                    <p>
                      This moulding was made in Italy in accordance with the
                      highest EU material and production standards and will be
                      shipped to you using nearly 100% recycled materials.
                    </p>
                    <p>
                      We are committed to sourcing natural, environmentally
                      sustainable woods, we use only water-based paints, and our
                      wood paste is 100% natural, composed of fine wood
                      particles, natural oils, and glues.
                    </p>
                    <p>
                      Roma also partners with One Tree Planted and the Nature
                      Conservancy to positively impact communities and
                      ecosystems all over the world.{" "}
                    </p>
                    <div class="flex gap-6 items-center">
                      <a
                        href="https://onetreeplanted.org/"
                        target="_blank"
                        class="w-24 block"
                      >
                        <Img
                          src={otp}
                          alt="One Tree Planted Logo"
                          width={96}
                          height={22}
                        />
                      </a>
                      <a
                        href="https://www.nature.org/"
                        target="_blank"
                        class="w-24 block"
                      >
                        <Img
                          src={tnc}
                          alt="The Nature Conservatory Logo"
                          width={96}
                          height={28}
                        />
                      </a>
                    </div>
                    <A
                      aria-label="Learn more about our sustainability initiatives"
                      class="text-sm text-roma-blue"
                      href="/about/sustainability"
                    >
                      <span class="sr-only">
                        Learn more about our sustainbility initiatives
                      </span>
                      Learn More
                    </A>
                  </div>
                </Collapsible>
                <Collapsible
                  name="return-exchange"
                  trigger="Returns & Exchanges"
                  triggerClass="font-bold"
                >
                  <div class="my-4 text-roma-dark-grey text-sm leading-7 child:pb-3">
                    <p>
                      In our mission to Move, Inspire and WOW, we at Roma
                      Moulding would like to ensure that you are truly happy
                      with your purchase.
                    </p>
                    <p>
                      If you receive your order and you are not completely
                      satisfied with the product, returning it is just as easy
                      as ordering it!
                      <A
                        aria-label="Learn more about our return and exchange policy"
                        href="/support/returns-exchanges"
                        class="text-roma-blue ml-1"
                      >
                        <span class="sr-only">
                          Learn more about our return and exchange policy
                        </span>
                        Learn More
                      </A>
                    </p>
                  </div>
                </Collapsible>
                <div class="flex gap-4 text-xs font-medium text-roma-dark-grey items-center justify-between ">
                  {/* <Show when={import.meta.env.VITE_PROD === true}> */}

                  <button
                    aria-label="Need help? Launch support chat"
                    class="flex items-center gap-2 hover:text-roma-blue"
                    onClick={() => {
                      launchLiveChat(
                        isPartner() && session()?.name ? session()?.name : "",
                        // TODO - add company number/ID number to
                        // isPartner() && session.company_number ? session.company_number : "",
                        ""
                      );
                    }}
                  >
                    <Icon path={chatBubbleLeft} class="w-5" />
                    <p>Need Help?</p>
                  </button>

                  <Show
                    // when={false}
                    when={
                      !props.location.pathname.includes("gallery-frame") &&
                      !props.location.pathname.includes("photo-frame")
                    }
                  >
                    <button
                      aria-label="Open frame calculator"
                      onClick={() => {
                        setCalcModal(true);
                      }}
                      class="flex items-center gap-2 hover:text-roma-blue"
                    >
                      <Icon path={calculator} class="w-5" />
                      <p>Frame Calculator</p>
                    </button>
                  </Show>
                  <Show when={isPartner()}>
                    <Show when={product()} keyed>
                      {(prod) => (
                        <AssetDownloadButton
                          sku={prod.SKU}
                          availableList={prod.AvailableAs}
                          type={type()}
                          product={prod}
                        />
                      )}
                    </Show>
                  </Show>
                </div>
              </div>
            </main>
            <section class="mt-10 sm:mt-28 boundary-outer flex flex-col gap-4 sm:gap-8 pb-10 sm:pb-16">
              <Suspense>
                <BaseProductSlider
                  title="You May Also Like"
                  productList={suggestionList()!}
                  analyticsListName="PDP Recommendation"
                />
                <ClientRecentlyViewed />
              </Suspense>
            </section>
          </Body>
        </Show>
      </Suspense>
    </ErrorBoundary>
  );
}
